<template>
  <div
    id="outage"
    class="
sticky fixed shadow top-0 w-full z-200
"
  >
    <div class="bg-red-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-red-800">
              <svg
                class="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill-rule="evenodd"
                fill="#ffffff"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
                />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden">
                Unavailable
              </span>
              <span class="hidden md:inline">
                For the time being, this site is unavailable.
              </span>
              <span class="hidden md:block pt-0">
                I apologize for any inconvenience caused. Thank you for your
                understanding..
              </span>
            </p>
          </div>
          <!-- <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
        <button onClick="acceptCookies()" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600 bg-white hover:bg-indigo-50">
          <span class="md:hidden">I accept the use of cookies on this site.</span>
          <span class="hidden md:inline">Sweet, now go away.</span>
        </button>
      </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>

<style></style>
