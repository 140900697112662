<template navbar>
  <div
    id="navbar"
    :class="
      'navbar absolute top-0' + +(scroll > 100 ? 'navbar--scrolled shadow' : '')
    "
  >
    <div>
      <header
        class="inset-x-0 z-100 h-24 sm:h-32 flex items-center w-full fade-in-fwd"
      >
        <div class="container mx-auto px-6 flex items-center justify-between">
          <router-link to="/">
            <div
              :class="
                'hidden title uppercase text-gray-100 dark:text-white text-3xl ' +
                  (scroll > 250 ? 'md:inline-block' : '')
              "
            >
              Toby Royal
            </div>
            <div
              :class="
                'title uppercase text-gray-100 dark:text-white text-3xl font-black' +
                  (scroll > 250 ? 'md:hidden' : '')
              "
            >
              TR
            </div>
          </router-link>
          <div class="flex items-center">
            <nav
              class="font-spec text-gray-100 dark:text-white uppercase text-lg lg:flex items-center hidden"
            >
              <router-link to="/" class="py-2 px-6 flex">
                Home
              </router-link>
            </nav>
          </div>
        </div>
      </header>
    </div>

    <Cookie></Cookie>

    <div class="pb-20"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        scroll: 0,
      };
    },
    methods: {
      scrollFn() {
        this.scroll = window.pageYOffset;
      },
    },
    mounted() {
      this.scroll = window.pageYOffset;
    },
    created() {
      window.addEventListener("scroll", this.scrollFn);
    },
    destroyed() {
      window.removeEventListener("scroll", this.scrollFn);
    },
  };
</script>
<style>
  .navbar {
    top: 0px;
    right: 0;
    left: 0;
    z-index: 1030;
    transition: 0.5s;
  }
  .navbar.navbar--scrolled {
    background: rgb(1, 8, 24);
    color: black;
    transition: 0.5s;
  }
</style>
