import Vue from "vue";
import VueRouter from "vue-router";
import LetItSnow from "vue-let-it-snow";

Vue.use(LetItSnow);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/maintenance.vue"),
  },
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "../views/Index.vue"),
  // },
  // {
  //   path: "/start",
  //   name: "Work With Me",
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "../views/Start.vue"),
  // },
  // {
  //   path: "/onboarding",
  //   name: "Onboarding",
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "../views/onboarding/index.vue"),
  // },
  // {
  //   path: "/work",
  //   name: "My Work",
  //   component: () => import(/* webpackChunkName: "home" */ "../views/Work.vue"),
  // },
  // {
  //   path: "/drone",
  //   name: "Drone Videography",
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "../views/Drone.vue"),
  // },
  // {
  //   path: "/climate",
  //   name: "Climate Contributions",
  //   component: () => import("../views/Climate.vue"),
  // },
  // {
  //   path: "/cloud",
  //   name: "Private Cloud",
  //   component: () => import("../views/Cloud.vue"),
  // },
  {
    path: "*",
    name: "Error",
    component: () => import(/* webpackChunkName: "404" */ "../views/Index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.afterEach((to) => {
  document.title = to.name + " - Toby Royal";
});

export default router;
