<template footer>
  <footer class="bgNew dark:bg-gray-800 w-full py-8">
    <div class="max-w-screen-xl mx-auto px-4">
      <ul
        class="max-w-screen-md mx-auto text-lg font-light flex flex-wrap justify-between hidden"
      >
        <li class="my-2">
          <p
            class="text-gray-400 dark:text-gray-300 transition-colors duration-200"
            href="#"
          ></p>
        </li>
        <li class="my-2">
          <p class="text-gray-400" href="#"></p>
        </li>
      </ul>
      <div
        class="text-center text-gray-500 dark:text-gray-200 pt-10 sm:pt-12 font-light flex items-center justify-center"
      >
        &copy; Copyright 2019-{{ date }}&nbsp;<span class="text-gray-400"
          >Toby Royal (tobyroyal.codes)</span
        >&nbsp;&bull; All Rights Reserved.
      </div>
      <div
        class="text-center text-gray-800 pt-0 font-light flex items-center justify-center hidden"
      >
        Shoutout to&nbsp;<a
          href="https://dancodes.online/?ref=toby"
          target="_blank"
          >DanCodes</a
        >&nbsp;for continuing to push me beyond my limits and make some truly
        amazing things.
      </div>
    </div>
  </footer>
</template>

<script>
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
  export default {
    name: "Footer",
    data() {
      return {
        date: new Date().getFullYear(),
        proxy: uuidv4(),
      };
    },
  };
</script>
