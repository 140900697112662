import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// import { BootstrapVue } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vueHeadful from 'vue-headful';
import VueTailwind from 'vue-tailwind'


import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Promotion from '@/components/Promotion.vue'
import Cookie from '@/components/Cookie.vue'
import Outage from '@/components/Outage.vue'

import "tailwindcss/dist/tailwind.css";


library.add(faUserSecret);

Vue.config.productionTip = false;
// Vue.use(BootstrapVue);
Vue.component("fa", FontAwesomeIcon);
Vue.component('vue-headful', vueHeadful);
Vue.component('Navbar', Navbar)
Vue.component('Footer', Footer)
Vue.component('Promotion', Promotion)
Vue.component('Cookie', Cookie)
Vue.component('Outage', Outage)

Vue.use(VueTailwind)

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
